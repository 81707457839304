<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="" style="padding: 4rem;">
                <div class="py-4">
                    <div>

                        <h2 class="fw-normal mb-4 me-3" v-if="edit_mode"><b>Editar Pago a proveedor #{{ edit_id }} </b></h2>
                        <h2 class="fw-normal mb-4 me-3" v-else><b>Registrar Pago a proveedor</b></h2>

                        <p v-if="edit_mode">Modificar un pago a un proveedor existente. 
                        </p>
                        <p v-else>Agregar un pago a un proveedor al sistema 
                            junto a su correspondiente factura, asociada a un despacho.
                        </p>
                    </div>
                    <div class="row pt-4 px-1">

                        <div class="col-4 mb-4">
                            <label for="client" class="form-label">Seleccionar cliente:</label>
                            <select disabled @change="clientSelected($event)" class="form-select"
                                aria-label="Default select example" 
                                v-model="dataForm.client.value" v-if="edit_mode">
                                <option v-for="client in clients" :value="client.id_client">{{ client.name }}
                                </option>
                            </select>

                            <select @change="clientSelected($event)" class="form-select"
                                aria-label="Default select example" 
                                v-model="dataForm.client.value" v-else>
                                <option v-for="client in clients" :value="client.id_client">{{ client.name }}
                                </option>
                            </select>


                            <span v-if="dataForm.client.err" class="text-danger">Debe seleccionar un cliente</span>
                        </div>


                        <div class="col-4 mb-4">
                            <label for="date" class="form-label">Fecha</label>
                            <div class="input-group mb-3">
                                <VueDatePicker :format="dateFormat" v-model="dataForm.date.value" locale="cl" >
                                </VueDatePicker>
                            </div>
                            <span v-if="dataForm.date.err" class="text-danger">Debe seleccionar una fecha</span>
                        </div>

                    </div>
                    <div class="row pt-0 px-1">


                        <div class="col-4 mb-4">
                            <label for="currenci" class="form-label">Seleccionar divisa</label>
                            <select disabled class="form-select" aria-label="Default select example"
                                v-model="dataForm.currenci.value"
                                @change="formatAmount()"
                                v-if="edit_mode">
                                <option v-for="currenci in currencies" :value="currenci.id_currency">{{ currenci.name }}
                                </option>
                            </select>

                            <select class="form-select" aria-label="Default select example"
                                v-model="dataForm.currenci.value"
                                @change="formatAmountAndUpdate(dataForm.mount, dataForm.currenci)"
                                v-else>
                                <option v-for="currenci in currencies" :value="currenci.id_currency">{{ currenci.name }}
                                </option>
                            </select>
                            <span v-if="dataForm.currenci.err" class="text-danger">Debe seleccionar una divisa</span>
                        </div>

                        <div class="col-4 mb-4">
                            <label for="mount" class="form-label">Monto</label>
                            <input class="form-control" :class="{ 'border-danger': dataForm.mount.err }"
                                id="mount" placeholder="" 
                                v-model="dataForm.mount.value_formated" 
                                @blur="handlerBlur"
                                @input="formatAmountAndUpdate(dataForm.mount, dataForm.currenci)">
                            <span v-if="dataForm.mount.err" class="text-danger">Debe ingresar un monto</span>
                        </div>
                        

                    </div>
                    <div class="row pt-4 px-1">
                
                        <div class="col-4 mb-4">
                            <label for="reference" class="form-label">Referencia</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.reference.err }"
                                id="reference" placeholder="" v-model="dataForm.reference.value" @blur="handlerBlur">
                            <span v-if="dataForm.reference.err" class="text-danger">Debe ingresar una
                                referencia</span>
                        </div>
                        <div class="col-4 mb-4">
                            <label for="concept" class="form-label">Seleccionar concepto</label>
                            <select class="form-select" aria-label="Default select example"
                                v-model="dataForm.concept.value">
                                <option v-for="concept in concepts" :value="concept.id_concept">{{ concept.name }}
                                </option>
                            </select>
                            <span v-if="dataForm.concept.err" class="text-danger">Debe seleccionar un concepto</span>
                        </div>

                        
                        
                        

                        <!-- 
                        <div class="col-md-7 px-2 mx-3 row p-3 text-center" style='border:2px solid black'>
                            <div class="col-md-6 pt-1" v-if="dataForm.isSupply">

                                <p>Este pago sera descontado de una provision</p>
                            </div>
                            <div class="col-md-6 pt-1">
                                <button type="button" class="btn btn-primary" @click="verClientSupply = true">Descontar desde una
                                    provision del cliente</button>
                            </div>
                        </div>
                        -->
                    </div>
                </div>

                <div class="row pt-0 px-1">
                    <div class="col-4 mb-4">
                                <label for="id_payment_status" class="form-label">Estado</label>
                                <select  class="form-select"
                                    aria-label="Default select example" v-model="dataForm.id_payment_status.value">
                                    <option v-for="pstatus in allid_payment_status" 
                                        :value="pstatus.id_payment_status"
                                        :title="pstatus.description"
                                        >{{ pstatus.name }}
                                    </option>
                                </select>
                                <span v-if="dataForm.id_payment_status.err" class="text-danger">Debe seleccionar un estado</span>
                    </div>

                    <div class="col-4 mb-4">
                        <label for="companyService" class="form-label">Empresa que emitio el servicio (y documento)</label>
                        <input type="text" class="form-control"
                            :class="{ 'border-danger': dataForm2.companyService.err }" id="companyService"
                            placeholder="" v-model="dataForm2.companyService.value" @blur="handlerBlur">
                        <span v-if="dataForm2.companyService.err" class="text-danger">Debe ingresar una
                            empresa</span>
                    </div>

                </div>

                <div class="row pt-4 px-1">

                    <div class="col-4 mb-4">
                        <label for="documentNumber" class="form-label">Numero Documento</label>
                        <input type="text" class="form-control"
                            :class="{ 'border-danger': dataForm2.documentNumber.err }" id="documentNumber"
                            placeholder="" v-model="dataForm2.documentNumber.value" @blur="handlerBlur">
                        <span v-if="dataForm2.documentNumber.err" class="text-danger">Debe ingresar un numero de
                            documento</span>
                    </div>

                    <div class="col-4 mb-4">
                        <label for="documentType" class="form-label">Tipo Documento</label>
                        <select class="form-select" aria-label="Default select example"
                            v-model="dataForm2.documentType.value">
                            <option v-for="dtetype in dtetypes" :value="dtetype.id_dte_type">{{ dtetype.name }}
                            </option>
                        </select>
                    </div>

                </div>
                <div class="row pt-4 px-1">
                    
                    <div class="col-6 mb-4 px-4" >
                        
                        <div v-if="dataForm2.fileName == ''">
                            <label class="form-label mb-0" >
                                Adjuntar Documento
                            </label>
                            <DropFile @drag-end="updateFileBlob" :files="dataForm2.files" v-if="dataForm2.fileName == ''"/>
                            
                        </div>
                      
                        <span v-if="dataForm2.filerr" class="text-danger">
                            Debe adjuntar un documento</span>

                        
                        <div v-if="dataForm2.fileName != ''">
                            <label for="value-cot" class="form-label mb-0">Documento adjuntado</label>
                            <div class="text-dark row">
                                <a class="col-3">
                                    {{dataForm2.fileName }}</a>
                                <div class="col-1" @click="showModal = true"><a class="dropdown-item text-primary"
                                        href="#"> <i class="bi bi-eye px-2"></i></a></div>
                                <div class="col-1" @click="deleteArchive()"><a class="dropdown-item text-danger"
                                        href="#"> <i class="bi bi-trash px-2"></i></a></div>
                            </div>
                        </div>
                    </div>

                    <PreviewFile v-if="showModal" :blob="dataForm2.fileBlob" :mimeType="dataForm2.mime_type" :visible="showModal"
                                @close="showModal = false" />

                </div>
                <div class="row pt-4 px-1">
                    <hr>
                    
                    <div class="col-6 px-2 mx-3 row p-3 text-center" >
                        
                        <div class="col-md-6 text-dark" v-if="dataForm3.isShipping">
                            Este pago esta asociado al  despacho: {{ dataForm3.shippingSelect.shipping_number }}
                        </div>
                    </div>

                    <span v-if="dataForm3.errship" class="text-danger">
                        Debe asociar un despacho</span>

                    <vue3-datatable :search="search" :rows="shippings" :columns="columns2" order
                        :sortable="true" :sortColumn="'date'">
                        <template #select="data">
                            <button @click="toggleSelectionShipping(data.value)">
                                {{ isSelectedShipp(data.value) ? 'Desasociar' : 'Asociar' }}
                            </button>
                        </template>
                        <template #amount="data">
                            <p>{{ data.value.amount }} <strong>{{ data.value.initials }}</strong></p>
                        </template>
                        <template #client="data">
                            <p>{{ data.value.client.name }} </p>
                        </template>
                        <template #currency="data">
                            <p>{{ data.value.currency.initials }}</p>
                        </template>
                    </vue3-datatable>
                </div>
                        <div class="text-center text-lg-end mt-4 pt-2">
                            <button type="button" class="btn btn-md text-white mx-2"
                                style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;"
                                @click="create()" v-if="edit_mode">
                                Guardar
                            </button>
                            <button type="button" class="btn btn-md text-white mx-2"
                                style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;"
                                @click="create()" v-else>
                                Crear
                            </button>
                            <button type="button" @click="cancelAll()" class="btn btn-md text-white bg-danger"
                                style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;">
                                Cancelar
                            </button>
                        </div>

            </div>
        </main>



    </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import DropFile from './../../component/DropFile.vue';
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net";
import NavBar from './../../component/NavBar.vue';
import BarTop from './../../component/BarTop.vue';
import PreviewFile from './../../component/PreviewFile.vue';
import NumUtils from './../../component/NumUtils.vue';
import Vue3Datatable from "@bhplugin/vue3-datatable";




DataTable.use(DataTablesCore);

export default {
    components: {
        VueDatePicker,
        DropFile,
        DataTable,
        DataTablesCore,
        NavBar,
        PreviewFile,
        BarTop,
        NumUtils,
        Vue3Datatable
    },
    data() {
        return {
            dateFormat: "dd/MM/yyyy",
            collapsed: true,
            verClientSupply: false,
            verShipping: false,
            shippings: [],
            verBillNote: false,
            showModal: false,
            billNotes: [],
            supply: [],
            clients: [],
            edit_selected_shipping: 0, // en modo editar, el id_shipping original (db)
            id_old_dte_doc: 0, // en modo editar, el id_documents_dte original (db)
            old_dte_mime_type: "", // en modo editar, el tipo de archivo original (db)
            old_dte_filename: "",
            columns: [
                { field: 'select', title: 'Seleccionar' },
                { field: "id_clients_supply", title: "Identificador" },
                { field: "client", title: "Cliente" },
                { field: "items_supply", title: "Item" },
                { field: "amount", title: "Monto Total" },
                { field: "currency", title: "Moneda" },
            ], columns2: [
                { field: 'select', title: 'Seleccionar' },
                { field: "id_shipping", title: "ID" },
                { field: "shipping_number", title: "Numero Despacho" },
                { field: "date_shipping", title: "Fecha" },
                { field: "week", title: "Semana" },
                { field: "boarding_number", title: "Numero de embarque" },
                { field: "booking_number", title: "Numero de reserva" },
                { field: "type_operation", title: "Tipo operacion" },
                { field: "consignee", title: "Despachador" },
                { field: "port_departure_shipment", title: "Puerto de embarque" },
                { field: "country_destination", title: "Pais destino" }
            ],
            columns3: [
                { field: 'select', title: 'Seleccionar' },
                { field: "billing_note_number", title: "Nro Nota de cobro" },
                { field: "emit_date", title: "Fecha de emisión" },
                { field: "created_at", title: "Fecha creación" },
                { field: "rut", title: "Rut cliente" },
                { field: "name", title: "Nombre cliente" },
                { field: "initials", title: "Divisa" },
                { field: "emit_amount", title: "Monto" }
            ],
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            currencies: [],
            banks: [],
            concepts: [],
            dtetypes: [],
            allid_payment_status: [],
            // idClient: 0,
            dataForm3: {
                isBillingNote: false,
                isShipping: false,
                errship: false,
                shippingSelect: {},
                billingNoteSelect: {}
            },
            dataForm2: {
                /**
                 * id_documents_dte solo se usa en modo edición
                 */
                id_documents_dte: {
                    value: 0,
                    err: false,
                },
                documentType: {
                    value: 110,
                    err: false,
                },
                documentNumber: {
                    value: '',
                    err: false,
                },
                companyService: {
                    value: '',
                    err: false,
                },
                filerr: false,
                fileName: "",
                fileBlob: null,
                mime_type: "",
                files: []
            },
            dataForm: {
                sending: false,
                isSupply: false,
                supplySelect: {},

                /**
                 * Este id solo se usa en modo edición
                 */
                id_payment: {
                    value: 0,
                    err: false,
                },

                client: {
                    value: '',
                    err: false,
                },
                id_payment_status: {
                    value: '',
                    err: false,
                }, 
                date: {
                    value: null,
                    err: false,
                },
                mount: { 
                    value: '', // monto que efectivamente se envía a guardar
                    value_formated: '', // monto que se muestra por pantalla (input)
                    err: false,
                },
                currenci: {
                    value: 15,
                    err: false,

                },
                reference: {
                    value: '',
                    err: false,

                },
                concept: {
                    value: 1,
                    err: false,

                },

            }
        }
    },
    async mounted() {
        document.body.className = this.theme;
        let sessionData = localStorage.getItem('user');
        this.user = JSON.parse(sessionData);
        // this.idClient = this.user.id_company;

        let urlpath = window.location.pathname;
        let parts = urlpath.split('/');
        this.edit_mode = false;
        this.edit_id = 0;
        if (parts.length == 4) {
            if (!isNaN(Number(parts[3]))) {
                this.edit_mode = true;
                this.edit_id = Number(parts[3]);
            }
        }
        this.$swal({
            title: 'Cargando...',
            showConfirmButton: false,
            showCancelButton: false
        });
        this.$swal.showLoading();

        await this.getBanks();
        await this.getAllid_payment_status();
        await this.getDteTypes();
        await this.getConcepts();
        await this.getBillNotes();
        await this.getCurrencies();
        await this.getClients();
        

        let editError = false;
        if (this.edit_mode) {
            await this.getPaymentToEdit();
            await this.getShipping();

            let oldShippings = this.shippings.filter((shipping) => {
                return shipping.id_shipping == this.edit_selected_shipping;
            });

            if (oldShippings.length == 0) {
                self.$swal.fire({
                        icon: "error",
                        title: "Error",
                        text: "No se encontró el despacho asignado al pago",
                        showConfirmButton: true,
                        showCancelButton: false
                });
                editError = true;
            }

            this.dataForm3.isShipping = true;
            this.dataForm3.shippingSelect = oldShippings[0];
        }


        if(!editError){
            this.$swal.close();
        }

    },
    computed: {

    },
    methods: {

        updateFileBlob() {
            let newFile =  this.dataForm2.files[0];
            this.dataForm2.fileName = newFile["name"];
            this.dataForm2.mime_type = newFile["type"];
            this.dataForm2.fileBlob = this.dataForm2.files[0];
            this.dataForm2.filerr = false;
        },

        cancelAll() {
            window.location.href = '/pagoProveedores';
        },

        currencyChanged(event) {
            this.dataForm.$event
        },

        formatAmountAndUpdate(amountInput, currencySelect) {
            NumUtils.methods.formatAmountAndUpdate(amountInput, currencySelect);
        },

        isSelectedBill(data) {
            return this.dataForm3.billingNoteSelectSelect == data
        },
        isSelectedShipp(data) {
            return this.dataForm3.shippingSelect == data
        },
        isSelectedSupp(data){
            return this.dataForm.supplySelect == data
        },
        toggleSelectionSupply(data){
            if (this.dataForm.supplySelect == data) {
                this.dataForm.isSupply = false;
                this.dataForm.supplySelect = {};
            } else {
                this.dataForm.isSupply = true;
                this.dataForm.supplySelect = data;
            }
        },
        toggleSelectionBill(data) {
            
            if (this.dataForm3.billingNoteSelect == data) {
                this.dataForm3.isBillingNote = false;
                this.dataForm3.billingNoteSelect = {};
            } else {
                this.dataForm3.isBillingNote = true;
                this.dataForm3.billingNoteSelect = data;
            }

        },
        toggleSelectionShipping(data) {

            if (this.dataForm3.shippingSelect == data) {
                this.dataForm3.isShipping = false;
                this.dataForm3.shippingSelect = {};
            } else {
                this.dataForm3.isShipping = true;
                this.dataForm3.shippingSelect = data;
            }

        },

        async getShipping() {
            
            const self = this;

            let idclient = 0;
            if (self.dataForm.client.value == "") {
                self.shippings = [];
                self.dataForm3.isShipping = false;
                self.dataForm3.shippingSelect = {};
                return;
            } else {
                idclient = self.dataForm.client.value;
                self.dataForm3.isShipping = false;
                self.dataForm3.shippingSelect = {};
            }

            let req = {
                page: 1,
                per_page: 10,
                id_client: idclient
            }

            this.$swal({
                title: 'Cargando despachos...'
            });
            this.$swal.showLoading();

            await axios.post('/api/getShippings', req, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
               
                this.$swal.close();

                if (response.status == 200) {

                    self.shippings = response.data.data

                    //self.clientesSelect = response.data.clienteEmpresa;
                    
                } else {
                    self.shippings = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                } else {
                    
                    self.$swal.fire({
                        icon: "error",
                        title: "Error interno",
                        text: "Comuníquese con soporte.",
                        showConfirmButton: true,
                        showCancelButton: false
                        });
                }


            });
        },

        async clientSelected(value) {
            //console.log('Selected Option:', value);
            this.selectedOption = value;
            const self = this;
            await self.getShipping();
        },

        async getDteTypes() {
            const self = this;

            await axios.get('/api/dtetypes', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                //console.clear();
                //console.table(response.data);
                if (response.status == 200) {
                    self.dtetypes = response.data;

                    //self.clientesSelect = response.data.clienteEmpresa;
                    // this.$swal.close();
                } else {
                    self.dtetypes = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                } else {
                    self.$swal.fire({
                        icon: "error",
                        title: "Error interno",
                        text: "Comuníquese con soporte.",
                        showConfirmButton: true,
                        showCancelButton: false
                        });
                }
            });
        },



        deleteArchive() {
            const self = this;
            self.$swal.fire({
                icon: "question",
                title: "Estas seguro que deseas quitar este archivo adjunto?",
                showConfirmButton: true,
                confirmButtonText: "si, eliminar",
                showCancelButton: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.dataForm2.fileName = "";
                    this.dataForm2.fileBlob = null;
                    this.dataForm2.files = [];
                    this.dataForm2.files.splice(0); // borrar todo
                    //this.dataForm.newBill = true;
                }
            });
        },


        async downloadDteDoc() {
            const self = this;

            let downloadUrl = "/api/downloadDteFile/" + self.id_old_dte_doc;

            // let filename = "file";
            // let oldmime = this.old_dte_mime_type.toLowerCase();
            /*if (oldmime == "application/pdf") {
                filename = "file.pdf";
            } else if (oldmime == "application/vnd.ms-excel") {
                filename = "file.xls";
            } else if (oldmime == "application/vnd.oasis.opendocument.text") {
                filename = "file.odt";
            } else if (oldmime == "application/vnd.oasis.opendocument.spreadsheet") {
                filename = "file.ods";
            } else if (oldmime == "application/zip") {
                filename = "file.zip";
            } else if (oldmime == "application/x-7z-compressed") {
                filename = "file.7z";
            } else if (oldmime == "application/vnd.rar") {
                filename = "file.rar";
            } else if (oldmime == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                filename = "file.xlsx";
            } else if (oldmime == "text/plain") {
                filename = "file.txt";
            } else if (oldmime == "text/csv") {
                filename = "file.csv";
            } else if (oldmime == "image/jpeg") {
                filename = "file.jpg";
            } else if (oldmime == "image/png") {
                filename = "file.png";
            } */

            await axios.request({
                            method: 'get',
                            url: downloadUrl,
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                            },
                            responseType: 'blob', //important
                        })
            .then(({ data }) => {
                self.dataForm2.fileBlob = new Blob([data]);

                /*const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', filename); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();*/
            })
            .catch((error) => {
                self.$swal.fire({
                        icon: "error",
                        title: "Error interno",
                        text: "Comuníquese con soporte.",
                        showConfirmButton: true,
                        showCancelButton: false
                        });
            });
        },

        async getConcepts() {

            const self = this;

            try {
                const response = await axios.get('/api/concept', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.concepts = response.data;
            } catch (error) {
                console.error('Error fetching concepts:', error);
                self.$swal.fire({
                        icon: "error",
                        title: "Error interno",
                        text: "Comuníquese con soporte.",
                        showConfirmButton: true,
                        showCancelButton: false
                        });
            }
        },

        async getBillNotes() {

            const self = this;

            const req = {
                page: 1,
                per_page: 10
            }

            await axios.post('/api/getBillingNotes', req, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                
                if (response.status == 200) {

                    self.billNotes = response.data.data

                    //self.clientesSelect = response.data.clienteEmpresa;
                    // this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                } else {
                    self.$swal.fire({
                        icon: "error",
                        title: "Error interno",
                        text: "Comuníquese con soporte.",
                        showConfirmButton: true,
                        showCancelButton: false
                        });

                }


            });
        },

        async getSupply() {

            const self = this;

            this.$swal.update({ title: 'Cargando provisiones...' });
            this.$swal.showLoading();


            try {
                const response = await axios.get('/api/client-supplies', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });

                self.supply = response.data;
               
            } catch (error) {
                console.error('Error fetching supply:', error);
                self.$swal.fire({
                        icon: "error",
                        title: "Error interno",
                        text: "Comuníquese con soporte.",
                        showConfirmButton: true,
                        showCancelButton: false
                        });
            }
        },

        async getClients() {

            const self = this;

            await axios.get('/api/clients', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {
                    self.clients = response.data.filter((client)=>client.status==1);

                    //self.clientesSelect = response.data.clienteEmpresa;
                    //this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                } else {
                    self.$swal.fire({
                        icon: "error",
                        title: "Error interno",
                        text: "Comuníquese con soporte.",
                        showConfirmButton: true,
                        showCancelButton: false
                        });
                }
            });

        },

        async getBanks() {

            const self = this;

            this.$swal.update({ title: 'Cargando bancos...' });
            this.$swal.showLoading();

            try {
                const response = await axios.get('/api/banks', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.banks = response.data;
            } catch (error) {
                console.error('Error fetching banks:', error);
                self.$swal.fire({
                        icon: "error",
                        title: "Error interno",
                        text: "Comuníquese con soporte.",
                        showConfirmButton: true,
                        showCancelButton: false
                        });
            }
        },

        async getCurrencies() {

            const self = this;

            this.$swal.update({ title: 'Cargando divisas...' });
            this.$swal.showLoading();

            try {
                const response = await axios.get('/api/currencies', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.currencies = response.data.filter((divisa)=>{
                        return divisa.status == 1
                    });
            } catch (error) {
                console.error('Error fetching currencies:', error);
                self.$swal.fire({
                        icon: "error",
                        title: "Error interno",
                        text: "Comuníquese con soporte.",
                        showConfirmButton: true,
                        showCancelButton: false
                        });
            }
        },

        async getAllid_payment_status() {

            const self = this;

            try {
                const response = await axios.get('/api/payments-status', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.allid_payment_status = response.data;
            } catch (error) {
                console.error('Error fetching currencies:', error);
                self.$swal.fire({
                        icon: "error",
                        title: "Error interno",
                        text: "Comuníquese con soporte.",
                        showConfirmButton: true,
                        showCancelButton: false
                        });
            }
        },

        base64ToBlob(base64, contentType, sliceSize = 512) {
            const byteCharacters = atob(base64);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);
                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            return new Blob(byteArrays, { type: contentType });
        },

        async getPaymentToEdit() {

            this.$swal.update({ title: 'Cargando data de pago a proveedor...' });
            this.$swal.showLoading();

            const self = this;
            

            await  axios.post('/api/getSupplierPayments', 
                {
                    id_payment: this.edit_id,
                    include_file: true
                }, 
                {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {

                    // console.dir(response.data.data);
                    if (response.data.data.length == 0){
                        self.$swal.fire({
                        icon: "error",
                        title: "Error",
                        text: "Pago a proveedor no encontrado",
                        showConfirmButton: true,
                        showCancelButton: false
                        });
                    }
                    let clientData = response.data.data[0];

                    console.log(clientData["id_client"]);

                    self.dataForm.id_payment.value = this.edit_id;

                    self.dataForm.id_payment_status.value = clientData["id_payment_status"];

                    self.dataForm.client.value = clientData["id_client"];
                    self.dataForm.date.value = clientData["date_payment_created"];

                    self.dataForm.currenci.value = clientData["id_currency_payment"];
                     

                    //desde base de datos el valor viene con 2 decimales (15.00)
                    let amounts = NumUtils.methods.formatAmount(clientData["value_payment"],
                                                            clientData["id_currency_payment"])

                    if (amounts['value'] != null){
                        self.dataForm.mount.value = amounts['value'];
                        self.dataForm.mount.value_formated = amounts['value_formated'];
                    }

                    self.dataForm.reference.value = clientData["reference"];
                    
                    self.dataForm.concept.value = clientData["concept"];

                    self.dataForm2.documentNumber.value = clientData["doc_number"];
                    self.dataForm2.documentType.value = clientData["id_dte_type"];
                    
                    self.dataForm2.fileName = clientData["filename"];
                    self.dataForm2.mime_type  = clientData["mime_type"];
                    self.dataForm2.fileBlob = this.base64ToBlob(clientData["file"], 
                                                                clientData["mime_type"], 
                                                                512);
                    // self.dataForm2.files.push(self.dataForm2.fileBlob);

                    self.dataForm2.companyService.value = clientData["issuing_company"];

                    self.edit_selected_shipping = clientData["id_shipping"];

                    self.id_old_dte_doc = clientData["id_documents_dte"];
                    self.dataForm2.id_documents_dte.value = clientData["id_documents_dte"];

                    self.old_dte_mime_type = clientData["mime_type"];
                    
                    self.old_dte_filename = clientData["filename"];

                     

                    console.log("getPaymentToEdit");
                    //self. = response.data.data
                    //console.log(self.items)
                    //self.clientesSelect = response.data.clienteEmpresa;
                    
                } else {
                    //self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                } else {
                    self.$swal.fire({
                        icon: "error",
                        title: "Error interno",
                        text: "Comuníquese con soporte.",
                        showConfirmButton: true,
                        showCancelButton: false
                        });
                }
            });
        },

        closeModal() {
            const self = this;
            self.verClientSupply = false;
        },
        closeModal2() {
            const self = this;
            self.verShipping = false;
        },
        closeModal3() {
            const self = this;
            self.verBillNote = false;
        },
        
        updateCollapsed(value) {
          
            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {
        
            this.pinned = value; // Actualiza el estado
        },
        showreq(id) {
            const self = this;
          
        },
        create() {
            const self = this;

            self.dataForm.sending = true;

            let foundError = false;

            if (!self.dataForm.date.value || self.dataForm.date.value == '') {
                self.dataForm.date.err = true;
                foundError = true;
            } else {
                self.dataForm.date.err = false;
            }

            if (self.dataForm.mount.value == '') {
                self.dataForm.mount.err = true;
                foundError = true;
            } else {
                self.dataForm.mount.err = false;
            }
            
            if (self.dataForm.client.value == '') {
                self.dataForm.client.err = true;
                foundError = true;
            } else {
                self.dataForm.client.err = false;
            }

            if (self.dataForm.id_payment_status.value == '') {
                self.dataForm.id_payment_status.err = true;
                foundError = true;
            } else {
                self.dataForm.id_payment_status.err = false;
            }

            if (self.dataForm.reference.value == '') {
                self.dataForm.reference.err = true;
                foundError = true;
            } else {
                self.dataForm.reference.err = false;
            }

            if (self.dataForm2.documentNumber.value == '') {
                self.dataForm2.documentNumber.err = true;
                foundError = true;
            } else {
                self.dataForm2.documentNumber.err = false;
            }

            if (self.dataForm2.companyService.value == '') {
                self.dataForm2.companyService.err = true;
                foundError = true;
            } else {
                self.dataForm2.companyService.err = false;
            }


            if (self.dataForm2.files.length < 1) {
                if (self.edit_mode && self.dataForm2.fileBlob != null ) {
                    // En modo edición si existe un fileblob 
                    // pero no hay files[], es por que el usuario no ha
                    // reemplazado ni quitado el adjunto
                    self.dataForm2.filerr = false;

                } else {
                    self.dataForm2.filerr = true;
                    foundError = true;
                }
            } else {
                self.dataForm2.filerr = false;
            }

            if (!self.dataForm3.isShipping) { 
                self.dataForm3.errship = true;
                /*self.$swal.fire({
                            icon: "danger",
                            title: "Debe asociar un despacho",
                            showConfirmButton: false,
                            showCancelButton: false
                });*/
                foundError = true;
            } else {
                self.dataForm3.errship = false;
            }

            if (foundError) {
                return false;
            }


            this.$swal({
                title: 'Registrando pago...'
            });
            this.$swal.showLoading();
            let req = { form: self.dataForm, 
                        form2: this.dataForm2, 
                        form3: this.dataForm3}

            let urlpost = '/api/createSupplierPayment';

            if (self.edit_mode) {
                urlpost = '/api/updateSupplierPayment';
            }
            
            axios.post(urlpost,
                req,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    // this.$swal.close();

                    self.$swal.fire({
                            icon: "success",
                            title: "Pago registrado exitosamente",
                            showConfirmButton: true,
                            showCancelButton: false
                    }).then((result) => {
                        window.location.href = '/pagoProveedores';
                    });

                    
                }).catch(function (error) {

                    if (error.response.statusText == 'Unauthorized') {
                        localStorage.removeItem('accessToken');
                        window.location.href = './../../api/auth/logout'
                    } else {
                        self.$swal.fire({
                            icon: "error",
                            title: "Error interno",
                            text: "Comuníquese con soporte",
                            showConfirmButton: false,
                            showCancelButton: false
                            });
                    }
                });


        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>