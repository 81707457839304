<template>
    <div v-if="visible" class="modal">
        <div class="modal-content">
            
            <div v-if="mimeType.includes('image/')">
                <img :src="fileUrl" alt="Imagen" class="preview-image" />
            </div>
            <div v-else-if="mimeType === 'application/pdf'">
                <iframe :src="fileUrl" class="preview-pdf" frameborder="0"></iframe>
            </div>
            <div
                v-else-if="mimeType === 'application/msword' || mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'">
                <iframe :src="`https://view.officeapps.live.com/op/embed.aspx?src=${encodedUrl}`" class="preview-doc"
                    frameborder="0"></iframe>
            </div>
            <div v-else>
                <p>Tipo de archivo no soportado para previsualización.</p>
            </div>
           
        </div>
        <div class="modal-footer">
            <div class="px-3">
                <button @click="close" class="close-btn btn-primary text-white p-3 px-4" >X</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        blob: { type: Blob, required: true },
        mimeType: { type: String, required: true },
        visible: { type: Boolean, required: true },
    },
    computed: {
        fileUrl() {
            return URL.createObjectURL(this.blob);
        },
        encodedUrl() {
            return encodeURIComponent(this.fileUrl);
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
       
    },
    watch: {
        visible(val) {
            if (!val) URL.revokeObjectURL(this.fileUrl); // Liberar URL para evitar fugas de memoria
        },
    },
};
</script>

<style scoped>
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.preview-image {
    max-width: 100%;
    max-height: 100%;
}

.preview-pdf,
.preview-doc {
    width: 100%;
    height: 80vh;
}
</style>