<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="" style="padding: 4rem;">
                <div class="py-4">
                    <div>
                        <h2 class="fw-normal mb-4 me-3" v-if="edit_mode"><b>Editar cliente</b></h2>
                        <h2 class="fw-normal mb-4 me-3" v-else> <b>Crear cliente</b></h2>
                    </div>
                    <div class="row p-4">


                        <div class="col-6 mb-4">
                            <label for="clientCode" class="form-label">Código cliente</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.clientCode.err }" id="clientCode" placeholder=""
                                v-model="dataForm.clientCode.value" @blur="handlerBlur">
                            <span v-if="dataForm.clientCode.err" class="text-danger">Debe ingresar un
                                código de cliente</span>
                        </div>


                        <div class="col-6 mb-4">
                            <label for="name" class="form-label">Nombre</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.name.err }"
                                id="name" placeholder="" v-model="dataForm.name.value" @blur="handlerBlur">
                            <span v-if="dataForm.name.err" class="text-danger">Debe ingresar
                                el nombre</span>
                        </div>


                        <div class="col-6 mb-4">
                            <label for="rut" class="form-label">Rut</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.rut.err }"
                                id="rut" placeholder="" v-model="dataForm.rut.value" @blur="handlerBlur">
                            <span v-if="dataForm.rut.err" class="text-danger">Debe ingresar
                                un RUT válido</span>
                        </div>

                        <div class="col-6 mb-4">
                            <label for="phone" class="form-label">Teléfono</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.phone.err }"
                                id="phone" placeholder="" v-model="dataForm.phone.value" @blur="handlerBlur">
                            <span v-if="dataForm.phone.err" class="text-danger">Teléfono</span>
                        </div>

                        <div class="col-6 mb-4">
                            <label for="email" class="form-label">Correo</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.email.err }"
                                id="email" placeholder="" v-model="dataForm.email.value" @blur="handlerBlur">
                            <span v-if="dataForm.email.err" class="text-danger">Debe ingresar un
                                correo electrónico válido</span>
                        </div>

                        <div class="col-6 mb-4">
                            <label for="country" class="form-label">País</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.country.err }"
                                id="country" placeholder="" v-model="dataForm.country.value" @blur="handlerBlur">
                            <span v-if="dataForm.country.err" class="text-danger">Debe ingresar
                                el País</span>
                        </div>

                        <div class="col-6 mb-4">
                            <label for="region" class="form-label">Región</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.region.err }"
                                id="region" placeholder="" v-model="dataForm.region.value" @blur="handlerBlur">
                            <span v-if="dataForm.region.err" class="text-danger">Debe ingresar
                                la región</span>
                        </div>

                        <div class="col-6 mb-4">
                            <label for="city" class="form-label">Ciudad</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.city.err }"
                                id="city" placeholder="" v-model="dataForm.city.value" @blur="handlerBlur">
                            <span v-if="dataForm.city.err" class="text-danger">Debe ingresar
                                la ciudad</span>
                        </div>

                        <div class="col-6 mb-4">
                            <label for="address" class="form-label">Dirección</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.address.err }"
                                id="address" placeholder="" v-model="dataForm.address.value" @blur="handlerBlur">
                            <span v-if="dataForm.address.err" class="text-danger">Debe ingresar
                                la dirección</span>
                        </div>



                        <div class="col-6 mb-4">
                            <label for="postalCode" class="form-label">Código postal</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.postalCode.err }" id="postalCode" placeholder=""
                                v-model="dataForm.postalCode.value" @blur="handlerBlur">
                            <span v-if="dataForm.postalCode.err" class="text-danger">Debe ingresar
                                el código postal</span>
                        </div>
                        <h5 class="text-black">Tipo de Alerta</h5>
                        <div>
                            <div class="form-check">
                                <input class="form-check-input" v-model="dataForm.noty_client_email.value" type="checkbox" value="" id="noty_client_email">
                                <label class="form-check-label" for="noty_client_email">
                                    Notificar por correo
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" v-model="dataForm.noty_user_company.value" type="checkbox" value="" id="noty_user_company">
                                <label class="form-check-label" for="noty_user_company">
                                    Notificar usuarios del sistema
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="text-center text-lg-end mt-4 pt-2">
                        <template v-if="edit_mode">
                            <button type="button" class="btn btn-md text-white mx-2"
                                style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;"
                                @click="createOrEditClient()">
                                Guardar
                            </button>
                        </template>
                        <template v-else>
                            <button type="button" class="btn btn-md text-white mx-2"
                                style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;"
                                @click="createOrEditClient()">
                                Crear
                            </button>
                        </template>
                        <a class="btn btn-md text-white bg-danger"
                            style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;"
                            href="/clientes">Cancelar</a>

                    </div>

                </div>
            </div>
        </main>
    </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net";
import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';

DataTable.use(DataTablesCore);

export default {
    components: {
        VueDatePicker,
        DataTable,
        DataTablesCore,
        NavBar,
        BarTop
    },
    data() {
        return {
            collapsed: true,
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            roles: [

            ],
            idClient: 0,
            dataForm: {
                sending: false,
                clientCode: {
                    value: '',
                    err: false
                },
                rut: {
                    value: '',
                    err: false
                },
                name: {
                    value: '',
                    err: false
                },
                phone: {
                    value: '',
                    err: false
                },
                email: {
                    value: '',
                    err: false
                },
                alert_type: {
                    value: 1,
                    err: false
                },
                country: {
                    value: '',
                    err: false
                },
                region: {
                    value: '',
                    err: false
                },
                address: {
                    value: '',
                    err: false
                },
                city: {
                    value: '',
                    err: false
                },
                postalCode: {
                    value: '',
                    err: false
                },
                noty_user_company:{
                    value: false,
                    err: false
                },
                noty_client_email:{
                    value: true,
                    err: false
                }
            }
        }
    },
    mounted() {
        document.body.className = this.theme;
        let sessionData = localStorage.getItem('user');
        this.user = JSON.parse(sessionData);
        this.id_company = this.user.company.id_company;

        let urlpath = window.location.pathname;
        let parts = urlpath.split('/');
        this.edit_mode = false;
        this.edit_id = 0;
        this.cliente = {};
        if (parts.length == 4) {
            if (!isNaN(Number(parts[3]))) {
                this.edit_mode = true;
                this.edit_id = Number(parts[3]);
                this.getCliente(this.edit_id);
            }
        }

    },
    computed: {

    },
    methods: {
        updateCollapsed(value) {
            console.log('Evento recibido:', value); // Para depuración
            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {
            console.log('Evento recibido pinn:', value); // Para depuración
            this.pinned = value; // Actualiza el estado
        },
        showreq(id) {
            const self = this;
            console.log(id);
        },

        // obtener datos del cliente para
        // edición
        getCliente(id_cliente) {
            this.$swal({
                title: 'Cargando...'
            });
            this.$swal.showLoading();
            const self = this;

            axios.get('/api/clients/' + id_cliente, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                self.$swal.close();
                // console.log(response.data)
                if (response.status == 200) {
                    self.cliente = response.data;
                    self.dataForm.name.value = self.cliente['name'];

                    self.dataForm.clientCode.value = self.cliente['client_code'];
                    self.dataForm.rut.value = self.cliente['rut'];
                    self.dataForm.name.value = self.cliente['name'];
                    self.dataForm.noty_client_email.value = self.cliente['noty_client_email']== 1 ? true : false;
                    self.dataForm.noty_user_company.value = self.cliente['noty_user_company'] == 1 ? true : false;
                    
                    self.dataForm.email.value = self.cliente['email'];
                    self.dataForm.country.value = self.cliente['country'];
                    self.dataForm.region.value = self.cliente['region'];
                    self.dataForm.address.value = self.cliente['address'];
                    self.dataForm.city.value = self.cliente['city'];
                    if (self.cliente['phone'] != null) {
                        self.dataForm.phone.value = self.cliente['phone'];
                    }
                    if (self.cliente['postal_code'] != null) {
                        self.dataForm.postalCode.value = self.cliente['postal_code'];
                    }

                } else {
                    self.cliente = {};
                }
            }).catch(function (error) {
                self.$swal.close();
                console.log(error);

                if (error.response.statusText) {
                    if (error.response.statusText == 'Unauthorized') {
                        localStorage.removeItem('accessToken');
                        window.location.href = './../../api/auth/logout';
                    }
                    if (error.response.status == 404) {
                        self.$swal.fire({
                            icon: "error",
                            title: "Cliente no encontrado",
                            showConfirmButton: false,
                            showCancelButton: false
                        });
                    } else {
                        self.$swal.fire({
                            icon: "error",
                            title: "Error interno al obtener datos de cliente",
                            text: "Comuníquese con soporte.",
                            showConfirmButton: false,
                            showCancelButton: false
                        });
                    }
                } else {
                    self.$swal.fire({
                        icon: "error",
                        title: "Error obteniendo datos de cliente",
                        text: "Comuníquese con soporte.",
                        showConfirmButton: false,
                        showCancelButton: false
                    });
                }
            });
        },

        validaRut(rut) {
            if (typeof rut !== 'string') {
                return false
            }
            if (!rut.includes("-")) {
                return false
            }
            if (!/^([1-9]\d{0,2}(\.?\d{3})*)-?[\dkK]$/.test(rut)) {
                return false
            }
            rut = typeof rut === 'string'
                ? rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase()
                : ''
            let t = parseInt(rut.slice(0, -1), 10)
            let m = 0
            let s = 1
            while (t > 0) {
                s = (s + (t % 10) * (9 - (m++ % 6))) % 11
                t = Math.floor(t / 10)
            }
            const v = s > 0 ? '' + (s - 1) : 'K'
            return v === rut.slice(-1)
        },
        preformatRut(rut) {
            if (rut.length < 2) {
                return rut;
            }
            if (rut.includes("-")) {
                return rut;
            } else {
                return [rut.slice(0, -1), "-", rut.slice(-1)].join('');
            }
        },
        formatRut(rut, withDots) {
            let parts = rut.split('-');
            let nums = parts[0].replaceAll('.', '');
            if (withDots) {
                let rutnum = new Intl.NumberFormat('es-CL').format(nums);
                return rutnum + "-" + parts[1];
            } else {
                return nums + "-" + parts[1];
            }
        },

        validateEmail(email) {
            // Expresión regular para validar el formato de correo electrónico
            const emailPattern = /^[^\s@]+@[^\s@]+.[^\s@]+$/;
            return emailPattern.test(email);
        },

        createOrEditClient() {
            const self = this;
            // console.log(self);
            // console.log(self.formData);
            self.dataForm.sending = true;

            let foundError = false;

            if (self.dataForm.clientCode.value == '') {
                self.dataForm.clientCode.err = true;
                foundError = true;
                //return false;
            } else {
                self.dataForm.clientCode.err = false;
            }

            self.dataForm.rut.value = self.preformatRut(self.dataForm.rut.value)
            if (self.dataForm.rut.value == '') {
                self.dataForm.rut.err = true;
                foundError = true;
                //return false;
            } else {
                self.dataForm.rut.value = self.formatRut(self.dataForm.rut.value, true);
                self.dataForm.rut.err = false;
            }
            if (self.dataForm.name.value == '') {
                self.dataForm.name.err = true;
                foundError = true;
                //return false;
            } else {
                self.dataForm.name.err = false;
            }
            //if (self.dataForm.phone.value == '') {
            //    self.dataForm.phone.err = true;
            //    return false;
            //}
            if (self.dataForm.email.value == '') {
                self.dataForm.email.err = true;
                foundError = true;
                //return false;
            } else {
                if (!self.validateEmail(self.dataForm.email.value)) {
                    self.dataForm.email.err = true;
                    foundError = true;
                    //return false;
                } else {
                    self.dataForm.email.err = false;
                }
            }
            if (self.dataForm.country.value == '') {
                self.dataForm.country.err = true;
                foundError = true;
                //return false;
            } else {
                self.dataForm.country.err = false;
            }
            if (self.dataForm.region.value == '') {
                self.dataForm.region.err = true;
                foundError = true;
                //return false;
            } else {
                self.dataForm.region.err = false;
            }
            if (self.dataForm.city.value == '') {
                self.dataForm.city.err = true;
                foundError = true;
                //return false;
            } else {
                self.dataForm.city.err = false;
            }
            if (self.dataForm.address.value == '') {
                self.dataForm.address.err = true;
                foundError = true;
                //return false;
            } else {
                self.dataForm.address.err = false;
            }

            //if (self.dataForm.postalCode.value == '') {
            //    self.dataForm.postalCode.err = true;
            //    return false;
            //}
            if (foundError) {
                return false
            }

            let chmsg = 'Creando cliente...';

            if (this.edit_mode) {
                chmsg = 'Editando cliente...' // para editar
            }

            self.$swal({
                title: chmsg
            });
            self.$swal.showLoading();

            console.log(self.dataForm);

            let urlpost = '/api/clients' // para crear
            if (this.edit_mode) {
                urlpost = '/api/clients/' + this.edit_id + '?_method=PATCH' // para editar
            }

            axios.post(urlpost,
                {
                    email: self.dataForm.email.value,
                    address: self.dataForm.address.value,
                    noty_user_company:self.dataForm.noty_user_company.value,
                    noty_client_email:self.dataForm.noty_client_email.value,
                    country: self.dataForm.country.value,
                    region: self.dataForm.region.value,
                    city: self.dataForm.city.value,
                    client_code: self.dataForm.clientCode.value,
                    name: self.dataForm.name.value,
                    rut: self.formatRut(self.dataForm.rut.value, false),
                    id_company: self.id_company,
                    phone: self.dataForm.phone.value,
                    postal_code: self.dataForm.postalCode.value
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                }).then(response => {
                    //console.log(response.data)
                    self.$swal.close();
                    if (response.status == 201) {

                        self.$swal({
                            title: "Cliente agregado exitosamente",
                            showDenyButton: false,
                            showCancelButton: false,
                            showConfirmButton: true,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            window.location.href = '/clientes';
                        })


                    } else if (response.status == 200) {

                        self.$swal({
                            title: "Cliente editado exitosamente",
                            showDenyButton: false,
                            showCancelButton: false,
                            showConfirmButton: true,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            window.location.href = '/clientes';
                        })

                    } else {
                        console.log(response.data);

                        self.$swal.fire({
                            icon: "error",
                            title: "Error al crear cliente",
                            text: "Comuníquese con soporte.",
                            showConfirmButton: false,
                            showCancelButton: false
                        });
                    }
                }).catch(function (error) {
                    self.$swal.close();

                    if (error.response.statusText) {
                        if (error.response.statusText == 'Unauthorized') {
                            localStorage.removeItem('accessToken');
                            window.location.href = './../../api/auth/logout'
                        } else {
                            console.log(error.response.data.errors.rut)
                            if (error.response.data.errors.email) {
                                self.$swal.fire({
                                    icon: "error",
                                    title: "Error!",
                                    text: "Correo ya existe en el sistema.",
                                    showConfirmButton: true,
                                    showCancelButton: false
                                });
                            }
                            else if (error.response.data.errors.rut) {
                                self.$swal.fire({
                                    icon: "error",
                                    title: "Error!",
                                    text: "Rut ya existe en el sistema.",
                                    showConfirmButton: true,
                                    showCancelButton: false
                                });
                            } else {
                                self.$swal.fire({
                                    icon: "error",
                                    title: "Error!",
                                    text: "Error al generar usuario.",
                                    showConfirmButton: true,
                                    showCancelButton: false
                                });
                            }
                        }
                    } else {
                        console.log(error);
                        if (error.response.data.errors.email) {
                            self.$swal.fire({
                                icon: "error",
                                title: "Error!",
                                text: "Correo ya existe en el sistema.",
                                showConfirmButton: true,
                                showCancelButton: false
                            });
                        }
                        else if (error.response.data.errors.rut) {
                            self.$swal.fire({
                                icon: "error",
                                title: "Error!",
                                text: "Rut ya existe en el sistema.",
                                showConfirmButton: true,
                                showCancelButton: false
                            });
                        } else {
                            self.$swal.fire({
                                icon: "error",
                                title: "Error!",
                                text: "Error al generar usuario.",
                                showConfirmButton: true,
                                showCancelButton: false
                            });
                        }

                    }
                });
        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>