<template>
</template>

<script>
export default {
    props: {

    },
    computed: {

    },
    methods: {

        /**
         * 
         * @param amount el monto a formatear
         * @param currencyId el id de la divisa con el número de divisa según la iso 4217
         */
        formatAmount(amount, currencyId) {

            let currencyCode = currencyId;
            if (currencyCode == "" || currencyCode == 0) {
                amountInput.value = '';
                return {value_formated: null, 
                         value: null};
            }
            let monto = Number(amount);
            if (monto == NaN) {
                return {value_formated: null, 
                        value: null};
            }

            console.log(currencyCode);
            // event.target.value = 8;
            //let local = undefined; // toma el local del navegador después
            let local = 'es-CL'; // forzar fomato a puntos 
            let currency = 'CLP'
            if (currencyCode == 15) { // 15 es CLP
                //local = 'es-CL';
                currency = 'CLP';
            }
            if (currencyCode == 1) { // 1 es USD
                //local = 'en-US';
                currency = 'USD';
            }
            if (currencyCode == 2) { // 2 es Euro
                //local = 'en-US';
                currency = 'EUR';
            }

            let formateado =  new Intl.NumberFormat(local, {
                style: 'decimal',
                //currencyDisplay: "narrowSymbol",
                //currencySign: "accounting",
                currency: currency,
                minimumFractionDigits: 0 
            }).format(monto);


            return {value_formated: formateado, 
                    value: monto};

        },


        /**
         * 
         * @param amountInput el input (tipo text) con el monto, debe tener un campo "value" y un campo "formated_value"
         * @param currencySelect el select de la divisa, de tener un campo "value" con el número de divisa según la iso 4217
         */
        formatAmountAndUpdate(amountInput, currencySelect) {

            let montoRaw = amountInput.value_formated.replace(/\D/g,'');
            if (montoRaw == "") {
                amountInput.value = '';
                amountInput.value_formated = '';
                return 
            }
            let res = this.formatAmount(montoRaw, currencySelect.value)
            
            if (res['value_formated'] == null) {
                amountInput.value = '';
                amountInput.value_formated = '';
            } else {
                amountInput.value_formated = res['value_formated'];
                amountInput.value = res['value'];
            }
            
        },

        
       
    },
    watch: {

    },
};
</script>