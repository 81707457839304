<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.bh-table-responsive input[type=checkbox] {
    width: 2rem;
    height: 1rem;
    opacity: 1;
    position: inherit;
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="p-5 pt-2">
                <div class="py-4">
                    <h2 class="">Lista de provisiones para {{ clientName }}</h2>
                    <div class="text-end">
                        <a class="btn btn-success" @click="verModalAdd = true">agregar provision</a>
                    </div>
                </div>
                <div v-if="clientSupply.length == 0" class="text-center">
                    <h2 class="mt-5">No hay provisiones ingresadas</h2>
                </div>
                <div v-if="clientSupply.length > 0">
                    <div class="mb-5">
                        <input v-model="search" type="text" class="form-input max-w-xs"
                            placeholder="Buscar provisiones..." />
                    </div>

                    <vue3-datatable :search="search" :rows="clientSupply" :columns="columns" order :sortable="true">
                        <template #item="data">
                            <span>{{ transformItem(data.value.client_supply_concept) }}</span>

                        </template>
                        <template #amount="data">
                            <span>{{data.value.amount > 0 ? formatCurrency(data.value.amount, data.value.currency.initials) : 0 }}</span>
                           
                        </template>
                    
                        <template #actions="data">
                            <div class=" form-switch">
                                <a class="btn" @click="editSupply(data.value.id_clients_supply)">
                                    <i class="bi bi-pencil px-2"></i>
                                </a>
                                <a class="btn" @click="deleteSupply(data.value.id_clients_supply)">
                                    <i class="bi bi-trash px-2"></i>
                                </a>
                            </div>
                        </template>
                    </vue3-datatable>
                </div>
            </div>
        </main>
        <!-- Modal -->
        <div class="modal-mb" v-show="verModalAdd">
            <div class="modal-dialo bg-white">
                <div class="modal-content">
                    <div class="modal-header row">
                        <h5 class="modal-title text-black col-10" id="exampleModalLabel">Añadir provision</h5>
                        <button type="button" class="btn-close col" data-bs-dismiss="modal" @click="closeModal()"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body mt-4">
                        <div class=" row">

                            <div class="col-md-4 mb-4 mt-4">
                                <label for="date" class="form-label">Fecha</label>
                                <div class="input-group mb-3">
                                    <VueDatePicker v-model="modalForm.date.value" locale="es" format="dd/MM/yyyy">
                                    </VueDatePicker>
                                    <span v-if="modalForm.date.err" class="text-danger">Debe ingresar una
                                    fecha</span>
                                </div>
                            </div>
                            <div class="col-md-4 mb-4 mt-4">
                                <label for="amount" class="form-label">Monto</label>
                                <input type="text" class="form-control"
                                    :class="{ 'border-danger': modalForm.amount.err }" id="amount" placeholder=""
                                    v-model="modalForm.amount.value" @blur="handlerBlur">
                                <span v-if="modalForm.amount.err" class="text-danger">Debe ingresar un
                                    monto</span>
                            </div>
                            <div class="col-md-4 mb-4 mt-4">
                                <label for="currency" class="form-label">Divisa</label>
                                <select class="form-select" aria-label="Default select example"
                                    v-model="modalForm.currency.value">
                                    <option v-for="currency in currencies" :value="currency.id_currency">{{
            currency.name }}
                                    </option>
                                </select>

                            </div>
                            <div class="col-md-7 mb-4 mt-4 row">
                                <label for="item" class="form-label">Conceptos (Seleccione uno o mas conceptos)</label>
                                <span v-if="modalForm.conceptErr" class="text-danger">Debe seleccionar almenos un
                                    concepto</span>
                                <div class="col" v-for="concept in concepts" :key="concept.id_concept">
                                    <input type="checkbox" :value="concept.id_concept" v-model="modalForm.concepts" />
                                    <br>
                                    <label>{{ concept.name }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer mt-3">
                        <button type="button" class="btn btn-secondary mx-2" v-if="!isEdit"
                            @click="addSuply()">Guardar</button>
                        <button type="button" class="btn btn-secondary mx-2" v-if="isEdit"
                            @click="updSuply()">Actualizar</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            @click="closeModal()">Cerrar</button>

                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>

import { ref } from "vue";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';

export default {
    components: {
        Vue3Datatable,
        VueDatePicker,
        NavBar,
        BarTop,
        ref
    },
    data() {
        return {
            collapsed: true,
            verModalAdd: false,
            id_client_supply: 0,
            isEdit: false,
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            currencies: [],
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
            clientName: '',
            search: '',
            items: [

            ],
            concepts: [],
            modalForm: {
                date: {
                    value: '',
                    err: false
                },
                amount: {
                    value: '',
                    err: false
                },
                currency: {
                    value: 15,
                    err: false
                },
                concepts: [],
                conceptErr: false
            },
            clientSupply: [],
            columns: [
                { field: "date_payment", title: "Fecha" },
                { field: "amount", title: "Monto", filter: false },
                
                { field: "item", title: "Items" },
                { field: 'actions', title: 'ACCION', sortable: false },

            ]
        }
    },
    mounted() {

        document.body.className = this.theme;
        let urlpath = window.location.pathname;
        let parts = urlpath.split('/');
        if (parts.length == 4) {
            if (!isNaN(Number(parts[3]))) {

                this.edit_id = Number(parts[3]);
                this.getClients(this.edit_id);
            }
        }
        this.getCurrencies();
        this.getConcepts();
    },
    computed: {

    },
    methods: {
        formatCurrency(value, currencyCode = 'CLP') {
            return currencyCode + ' '+ new Intl.NumberFormat('es-CL', {
                style: 'currency',
                currency: currencyCode,
                minimumFractionDigits: 0 // O puedes cambiar el valor según lo que necesites
            }).format(value);
        },
        async getConcepts() {

            try {
                const response = await axios.get('/api/concept', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                console.log(response.data);
                this.concepts = response.data;
            } catch (error) {
                console.error('Error fetching concepts:', error);
            }
        },
        async getCurrencies() {
            try {
                const response = await axios.get('/api/currencies', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.currencies = response.data;
            } catch (error) {
                console.error('Error fetching currencies:', error);
            }
        },
        transformItem(item) {
            // Úne el array en una cadena separada por comas y espacios

            return item.map(concept => concept.concept.name)
                .join(', ');;
        },
        closeModal() {
            this.verModalAdd = false;
        },
        updateCollapsed(value) {
            console.log('Evento recibido:', value); // Para depuración
            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {
            console.log('Evento recibido pinn:', value); // Para depuración
            this.pinned = value; // Actualiza el estado
        },
        updSuply() {
            const self = this;
           
            self.verModalAdd = false;
            let errFound = false;
            const validateField = (field) => {
                if (field.value === '' || field.value === 0 || !field.value) {
                    field.err = true;
                    field.msjErr = 'Este campo es obligatorio';
                    console.log(field)
                    errFound = true;
                } else {
                    field.err = false;
                    field.msjErr = '';
                }
            };
            validateField(this.modalForm.date);
            validateField(this.modalForm.amount);


            if (self.modalForm.concepts.length == 0) {
                errFound = true;
                self.modalForm.conceptErr = true;
            } else {
                self.modalForm.conceptErr = true;
            }
            // Verificar si hay errores
            if (errFound) {
                self.verModalAdd = true;
                return false
               
            }
            self.verModalAdd = false;
            const req = { form: self.modalForm, concepts: self.modalForm.concepts, id_client: self.edit_id, id_client_supply: self.id_client_supply }
            axios.post('/api/updateClientSupply',
                req,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {

                    if (response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            title: "provisiones del cliente actualizada exitosamente",
                            showConfirmButton: true,
                            confirmButtonText: "ok",
                            showCancelButton: false
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.getClients(this.edit_id);

                                self.modalForm.amount.value = ''
                                self.modalForm.currency.value = 15
                                self.modalForm.item.value = ''
                                self.modalForm.date.value = ''
                            }
                        });


                    } else {

                    }
                }).catch(function (error) {

                    if (error.response.statusText == 'Unauthorized') {
                        localStorage.removeItem('accessToken');
                        window.location.href = './../../api/auth/logout'
                    }
                });
        },
        addSuply() {
            const self = this;
           
            
            let errFound = false;
            const validateField = (field) => {
                if (field.value === '' || field.value === 0 || !field.value) {
                    field.err = true;
                    field.msjErr = 'Este campo es obligatorio';
                    console.log(field)
                    errFound = true;
                } else {
                    field.err = false;
                    field.msjErr = '';
                }
            };
            validateField(this.modalForm.date);
            validateField(this.modalForm.amount);


            if (self.modalForm.concepts.length == 0) {
                errFound = true;
                self.modalForm.conceptErr = true;
            } else {
                self.modalForm.conceptErr = true;
            }
            // Verificar si hay errores
            if (errFound) {
                self.verModalAdd = true;
                return false
               
            }
            self.verModalAdd = false;
            this.$swal.showLoading();
            const req = { form: self.modalForm, concepts: self.modalForm.concepts, id_client: self.edit_id }

            axios.post('/api/createClientSupply',
                req,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    console.log(response);
                    if (response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            title: "provisiones del cliente guardada exitosamente",
                            showConfirmButton: true,
                            confirmButtonText: "ok",
                            showCancelButton: false
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.getClients(this.edit_id);
                                self.modalForm.amount.value = ''
                                self.modalForm.currency.value = 15
                                self.modalForm.item.value = ''
                                self.modalForm.date.value = ''
                            }
                        });


                    } else {

                    }
                }).catch(function (error) {

                    if (error.response.statusText == 'Unauthorized') {
                        localStorage.removeItem('accessToken');
                        window.location.href = './../../api/auth/logout'
                    }
                });
        },
        editSupply(id_client_supply) {
            const self = this;
            axios.get(`/api/client-supplies/${id_client_supply}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {

                if (response.status == 200) {
                    self.modalForm.amount.value = response.data.amount;
                    self.modalForm.amount.err = false;
                    self.modalForm.currency.value = response.data.id_currency;
                    self.modalForm.concepts = response.data.client_supply_concept.map(
                        item => item.id_concept
                    );
                    if(self.modalForm.concepts.length == 0){
                        self.modalForm.conceptErr = true;
                    }else{
                        self.modalForm.conceptErr = false;
                    }
                    self.modalForm.date.value = response.data.date_payment;
                    self.modalForm.date.err = false;
                    self.id_client_supply = response.data.id_clients_supply;
                    self.isEdit = true;
                    this.verModalAdd = true;

                } else {

                }
            })
        },
        deleteSupply(id_client_supply) {
            const self = this;
            this.$swal.fire({
                icon: "warning",
                title: "Estas seguro que deseas eliminar esta provision?",
                showConfirmButton: true,
                confirmButtonText: "si, eliminar",
                showCancelButton: true,
                cancelButtonText: "no"
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete(`/api/deleteSupply/${id_client_supply}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {

                        if (response.status == 201) {
                            this.getClients(this.edit_id);
                            this.verModalAdd = false;
                            this.$swal.fire({
                                icon: "success",
                                title: "provision eliminada con exito",
                            })
                        } else {

                        }
                    }).catch(function (error) {

                        if (error.response.statusText == 'Unauthorized') {
                            localStorage.removeItem('accessToken');
                            window.location.href = './../../api/auth/logout'
                        }
                    });
                }
            });
        },
        getClients(id_client) {
            this.$swal({
                title: 'Cargando...'
            });
            this.$swal.showLoading();
            const self = this;

            axios.get('/api/clientSupply/' + id_client, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                self.$swal.close();
                // console.log(response.data)
                if (response.status == 200) {
                    this.clientSupply = response.data;
                } else {
                    self.cliente = {};
                }
            }).catch(function (error) {
                self.$swal.close();
                console.log(error);

                if (error.response.statusText) {
                    if (error.response.statusText == 'Unauthorized') {
                        localStorage.removeItem('accessToken');
                        window.location.href = './../../api/auth/logout';
                    }
                    if (error.response.status == 404) {
                        self.$swal.fire({
                            icon: "error",
                            title: "provision no encontrado",
                            showConfirmButton: false,
                            showCancelButton: false
                        });
                    } else {
                        self.$swal.fire({
                            icon: "error",
                            title: "Error interno al obtener provision del cliente",
                            text: "Comuníquese con soporte.",
                            showConfirmButton: false,
                            showCancelButton: false
                        });
                    }
                } else {
                    self.$swal.fire({
                        icon: "error",
                        title: "Error obteniendo provision de cliente",
                        text: "Comuníquese con soporte.",
                        showConfirmButton: false,
                        showCancelButton: false
                    });
                }
            });
        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>